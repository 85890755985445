<template>
	<b-modal id="activate-company" :title="modalTitle" ok-title="Save" ref="modal" @ok="handleOk"
		@cancel="handleCancel" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true" hide-header-close>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false"/>

		<div class="confirm-message" v-if="isActive === 'true'">
			<span v-if="activeCompanyConnections.length > 0">
				<span>
					Are you sure you want to
					<b>deactivate</b>
					this company account and all of its active connections and users?
				</span>
				<b-row class="my-2">
					<b-col sm="5">
						<b>Active Connections:</b>
					</b-col>
					<b-col sm="4">{{ activeConnectionsDisplay }}</b-col>
				</b-row>
				<b-row class="my-2">
					<b-col sm="5">
						<b>Active Users:</b>
					</b-col>
					<b-col sm="4">{{ activeUsersDisplay }}</b-col>
				</b-row>
			</span>

			<span v-else>
				Are you sure you want to
				<b>deactivate</b> this company <b>"{{ name }}"</b>?
			</span>
		</div>

		<div class="confirm-message" v-else>
			Are you sure you want to
			<b>activate</b> this company <b>"{{ name }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Utils
import { CompanyUtil } from '@/utils/companyUtil';
import { DateUtil } from '@/utils/dateutil';

// API & DAO
import connectionDAO from '@/database/connections';
import userDAO from '@/database/users';
import companyApi from '@/api/companyApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'activate-company',
	components: {
		Loading,
	},
	data() {
		return {
			selCompany: {},
			isActive: true,
			activeCompanyConnections: [],
			activeUsers: [],

			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		activeConnectionsDisplay: function () {
			let display = '';
			if (this.activeCompanyConnections.length === 0) {
				display = ' N/A ';
			} else {
				this.activeCompanyConnections.forEach((con) => {
					display += con.connectedCompany.name + ', ';
				});
				display = display.substring(0, display.length - 2);
			}
			return display;
		},
		activeUsersDisplay: function () {
			let display = '';
			if (this.activeUsers.length === 0) {
				display = ' N/A ';
			} else {
				this.activeUsers.forEach((user) => {
					display += user.emailAddress + ', ';
				});
				display = display.substring(0, display.length - 2);
			}
			return display;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.selCompany.name;
		},
		modalTitle() {
			return this.isActive === 'true' ? 'Deactivate Company' : 'Activate Company';
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelCompany', async (company) => {
			this.selCompany = company;
			this.isActive = company.isActive;

			await this.retrieveCompanyConnections();
			await this.retrieveUsers();
		});
	},
	methods: {
		async retrieveCompanyConnections() {
			this.activeCompanyConnections = [];

			let connectionsObj = await connectionDAO.getConnectionsByCompany(this.selCompany.id);
			if (connectionsObj && !_.isEmpty(connectionsObj)) {
				_.forEach(connectionsObj, con => {
					if (con.isActive === 'true') {
						this.activeCompanyConnections.push(con);
					}
				});
			}
		},
		async retrieveUsers() {
			let usersObj = await userDAO.getUsersByCompanyId(this.selCompany.id);

			this.activeUsers = _.filter(usersObj, (o) => {
				return o.isActive === 'true';
			});
		},
		toggleStatus(isActive) {
			let status = 'true';
			if (isActive === 'true') {
				status = 'false';
			}
			return status;
		},
		resetStatus() {
			this.selCompany.isActive = this.isActive;
			this.$store.commit('SET_CURR_COMPANY', this.selCompany);
			EventBus.$emit('onCloseActivateCompany', this.selCompany);
		},
		handleCancel() {
			this.resetStatus();
		},

		getCompanyObj(param) {
			// timestamp
			param.dateUpdated = DateUtil.getCurrentTimestamp();
			param.updatedBy =  this.loggedUser.id;

			return CompanyUtil.cleanupFields(param);
		},
		async handleOk(evt) {
			try {
				// Prevent modal from closing
				evt.preventDefault();

				// show loading indicator
				this.isLoading = true;

				// update the status
				this.selCompany.isActive = this.toggleStatus(this.isActive);

				let { data } = await companyApi.saveCompany(
					this.getCompanyObj(this.selCompany),
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					let successMsg = '';
					if (data.company.isActive === 'true') {
						successMsg = `Company "${this.name}" account is now activated!`;
					} else {
						successMsg = `Company "${this.name}" account is now deactivated!`;
					}

					this.$toaster.success(successMsg);
					EventBus.$emit('onCloseEditCompany', data);
					this.$refs.modal.hide();
				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There's a problem encountered during account activation of the company "${this.name}"`;
					} else {
						errorMsg = `There's a problem encountered during account deactivation of the company "${this.name}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (_error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There's a problem encountered during account activation of the company "${this.name}"`;
				} else {
					errorMsg = `There's a problem encountered during account deactivation of the company "${this.name}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelCompany');
	},
};
</script>