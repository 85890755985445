import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getCompanies(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getCompanies`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    saveCompany(company, currUserId, currTimeStamp) {
        const url = `${this.baseUrl}/saveCompany`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            company: JSON.stringify(company)
        });
    },

    updateCompanyLogo(logoDetails, currUserId, currTimeStamp) {
        const url = `${this.baseUrl}/updateCompanyLogo`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            logoDetails: JSON.stringify(logoDetails)
        });
    },

}